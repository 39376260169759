export const BANKLIST = [
  {code: 'INGP', name: 'Bank of Singapore Limited'},
  {code: 'DBSS', name: 'DBS Bank Ltd.'},
  {code: 'OCBC', name: 'Oversea-Chinese Bank Corp Limited (OCBC)'},
  {code: 'UOVB', name: 'United Overseas Bank Limited (UOB)'},
  {code: 'BKCH', name: 'Bank of China Limited'},
  {code: 'BNPA', name: 'BNP Paribas'},
  {code: 'PCBC', name: 'China Construction Bank Corporation'},
  {code: 'CITI', name: 'Citibank Singapore Limited'},
  {code: 'HSBC', name: 'HSBC Bank (Singapore) Limited'},
  {code: 'ICIC', name: 'ICICI Bank Limited'},
  {code: 'ICBK', name: 'Industrial and Commercial Bank of China Limited (ICBC)'},
  {code: 'MBBE', name: 'Maybank Singapore Limited'},
  {code: 'SCBL', name: 'Standard Chartered Bank (Singapore) Limited'},
  {code: 'SBIN', name: 'State Bank of India'},
  {code: 'BKKB', name: 'Bangkok Bank Public Company Limited'},
  {code: 'BOFA', name: 'Bank of America National Association'},
  {code: 'BKID', name: 'Bank of India'},
  {code: 'CIBB', name: 'CIMB Bank Berhad'},
  {code: 'CRLY', name: 'Credit Agricole Corporate and Investment Bank'},
  {code: 'HLBB', name: 'HL Bank'},
  {code: 'IDIB', name: 'Indian Bank'},
  {code: 'IOBA', name: 'Indian Overseas Bank'},
  {code: 'CHAS', name: 'JPMorgan Chase Bank N.A.'},
  {code: 'MHCB', name: 'Mizuho Bank LTD'},
  {code: 'BOTK', name: 'MUFG Bank LTD'},
  {code: 'BNIN', name: 'P.T. Bank Negara Indonesia (Persero) TBK'},
  {code: 'RHBB', name: 'RHB Bank Berhad'},
  {code: 'SMBC', name: 'Sumitomo Mitsui Banking Corporation'},
  {code: 'BEAS', name: 'The Bank of East Asia Limited'},
  {code: 'SBOS', name: 'Trust Bank Singapore Limited'},
  {code: 'UCBA', name: 'UCO Bank'},
];
